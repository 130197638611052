import { Database } from "@/lib/database.types";
import { createBrowserClient } from "@supabase/ssr";

const SupabaseClientComponent = () => {
  const supabase = createBrowserClient<Database>(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
  );
  return { supabase };
};

export default SupabaseClientComponent;
